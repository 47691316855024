import { render, staticRenderFns } from "./oldIndex.vue?vue&type=template&id=6c801879&scoped=true&"
import script from "./oldIndex.vue?vue&type=script&lang=js&"
export * from "./oldIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c801879",
  null
  
)

export default component.exports